/* eslint react/prop-types: 0, react/display-name: 0 */
import React from 'react'
import { LocaleProvider } from 'context/LocaleProvider'
import globalStyle from 'src/utils/styles/global';
import { theme } from 'src/utils/styles';
import { Global } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import { Wrapper } from 'components/Wrapper/Wrapper.css'
import './app.css'
import './src/utils/styles/splitting-cells.css'
import './src/utils/styles/splitting.css'

const wrapPageElement = ({ element, props }) => {
  return (
    <LocaleProvider locale={props.pageContext.locale}>
      <ThemeProvider theme={theme}>
        <Global styles={globalStyle} />
          <>{element}</>
          {/* <Wrapper {...props}>{element}</Wrapper> */}
      </ThemeProvider>
    </LocaleProvider>
  )
}

export default wrapPageElement
