import { css } from '@emotion/core'
import theme from './theme'

const list = css`
  ul {
    padding-left: 20px;
    list-style: none;
  }

  li {
    margin: 10px 0 0;
    position: relative;
    font-size: ${theme.fonts.body};
    letter-spacing: ${theme.letterSpacing.body};
    font-weight: normal;
    font-style: normal;
    line-height: 1.5;
    
    &:not(:last-of-type) {
      margin: 10px 0;
    }

    &::before {
      position: absolute;
      content: '';
      background: ${theme.colors.secondary};
      width: 2px;
      height: 100%;
      left: -20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`

export default list
