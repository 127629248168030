module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'LYŽAŘSKÁ ŠKOLA MAJOR', // Navigation and Site Title
  titleAlt: 'LYŽAŘSKÁ ŠKOLA MAJOR - Rokytnice nad Jizerou', // Title for JSONLD
  description: 'Vyučujeme sjezdové lyžování – od úplných základů až po pokročilý carving snowboarding telemark základy skialpinismu',
  headline: 'LYŽAŘSKÁ ŠKOLA MAJOR - Rokytnice nad Jizerou', // Headline for schema.org JSONLD
  url: 'https://majorskischool.cz/', // Domain of your site. No trailing slash!
  logo: './src/favicon.png', // Used for SEO
  ogLanguage: 'cs_CZ', // Facebook Language

  // JSONLD / Manifest
  favicon: './src/favicon.png', // Used for manifest favicon generation
  shortName: 'Major lyžařská škola', // shortname for manifest. MUST be shorter than 12 characters
  author: 'kristyna_dierstein', // Author for schemaORGJSONLD
  themeColor: '#3D63AE',
  backgroundColor: '#EBEDF2',

  twitter: '@starter_prismicio-i18n', // Twitter Username
  facebook: 'skischoolmajor', // Facebook Site Name
  googleAnalyticsID: 'UA-155216455-2',

  skipNavId: 'reach-skip-nav', // ID for the "Skip to content" a11y feature
}
