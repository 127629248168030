import styled from '@emotion/styled'

export const Wrapper = styled.div`

position: ${props => props.open ? 'fixed' : 'relative'};

.styles_scroll-to-top__2A70v {
  z-index: 1000;
  background-color: ${props => props.theme.colors.primaryGreen};
  padding: 2px;
  border-radius: 5px;
  opacity: .8;
  bottom: 20px;
  right: -3px;
  width: 35px;
  text-align: center;
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
  }

  svg:not(:root) {
    stroke: ${props => props.theme.colors.secondaryBlack};
    stroke-width: 24px;
  }

  svg {
    width: 30px;
    height: 16px;

    path {
      fill: ${props => props.theme.colors.secondaryBlack};
    }
  }
}
`
